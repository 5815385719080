import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import HomePage from "./pages/HomePage";
import { useEffect, useState } from "react";
import { ClientPrincipal } from "./models/ClientPrincipal";
import { getUserInfo, isUserLoggedIn } from "./services/UserService";
import Hero from "./components/Hero";
import { newTheme } from "./styles/NewTheme";


function App() {
  const [user, setUser] = useState<ClientPrincipal | null>(null);
  useEffect(() => {
    getUserInfo().then((user) => setUser(user));
  }, []);
  let loggedIn = isUserLoggedIn(user);
  return loggedIn ? (
    <ThemeProvider theme={newTheme}>
      <CssBaseline/>
        <Router>
          <HomePage />
        </Router>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={newTheme}>
        <CssBaseline/>
        <Router>
            <Hero
                mainText="Zeti Telematics Portal"
                bodyText={
                    <>
                        Login to add vehicle(s) to Zeti's ZERO platform
                    </>
                }
                ctaLink="/login"
                ctaText="Sign in / up"
            />
        </Router>
    </ThemeProvider>
  );
}

export default App;