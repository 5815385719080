import { Component } from 'react';
import axios from 'axios';
import Smartcar from '@smartcar/auth';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Connect } from '../components/Connect';
import { Vehicles } from '../components/Vehicles';
import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import newLogo from "./../styles/ZETI_Logo_White.png"

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vehicles: [],
            loading: true,
            numberOfVehiclesBeingSynced: 0,
            numberOfVehiclesThisUserWillHave: 0
        };

        this.authorize = this.authorize.bind(this);

        this.onComplete = this.onComplete.bind(this);

        this.checkForUpdates = this.checkForUpdates.bind(this);
    }

    componentDidMount() {
        axios
            .get(`/api/vehicle`)
            .then(res => {
                axios.get('/api/GetSmartCarDetails')
                    .then(response => {
                        const data = response.data
                        this.setState({ vehicles: res.data, loading: false });
                        let testMode = (data.testMode === 'true')
                        let config = {
                            clientId: data.clientId,
                            redirectUri: data.redirectUri,
                            scope: ['read_vehicle_info', 'read_location', 'read_odometer', 'read_vin', 'read_charge', 'read_battery', 'read_engine_oil', 'control_charge', 'read_fuel', 'control_security', 'read_tires'],
                            onComplete: this.onComplete,
                        }
                        if (testMode) config.testMode = testMode
                        this.smartcar = new Smartcar(config);
                    })
            });
    }

    onComplete(err, code, state) {
        this.setState({ loading: true })
        return axios
            .get(`/api/exchange?smartcarCode=${code}`, { headers: { "Accept": "application/json" } })
            .then(response => {
                const numberOfVehiclesBeingUpdated = response.data.smartcarVehiclesThatArentInZetiYet
                const numberOfVehiclesThisUserWillHave = response.data.numberOfVehiclesThisUserWillHave
                this.setState({ numberOfVehiclesBeingSynced: numberOfVehiclesBeingUpdated, numberOfVehiclesThisUserWillHave })
                return axios.get(`/api/vehicle`);
            })
            .then(res => {
                this.setState({ vehicles: res.data, loading: false });
                setInterval(this.checkForUpdates, 5000)
                setInterval(this.checkForUpdates, 10000)
                setInterval(this.checkForUpdates, 30000)
            });
    }

    checkForUpdates() {
        return axios
            .get(`/api/vehicle`)
            .then(res => {
                this.setState({ vehicles: res.data, loading: false });
            });
    }

    authorize() {
        this.smartcar.openDialog({ forcePrompt: true });
    }

    render() {
        return (
            <div style={{ paddingLeft: "58px" }}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{paddingTop:'20px'}}>
                    <Grid item xs={9} lg={11}>
                        <img src={newLogo} alt="zeti logo" style={{ height: '3.5rem' }} />
                    </Grid>
                    <Grid item xs={3} lg={1}>
                        <a href="/logout" style={{color:'white', fontSize: '16px'}}>
                            Sign out
                        </a>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{padding:'20px'}}>
                </Grid>
                <Typography variant="h4" gutterBottom>SmartCar (Integrated Telematics)</Typography>
                <Connect onClick={this.authorize} />
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5" gutterBottom>Your Vehicle(s)</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" gutterBottom>{this.state.vehicles.length}{this.state.numberOfVehiclesThisUserWillHave === 0 || this.state.numberOfVehiclesThisUserWillHave === this.state.vehicles.length ? "" : "/" + this.state.numberOfVehiclesThisUserWillHave}<IconButton color="primary" variant="outlined" onClick={() => this.checkForUpdates()}><RefreshOutlined /></IconButton></Typography>
                    </Grid>
                </Grid>
                {this.state.numberOfVehiclesThisUserWillHave === 0 || this.state.numberOfVehiclesThisUserWillHave === this.state.vehicles.length ?
                    <></>
                    :
                    <Typography align='right'>Onboarding will continue if you navigate away from the page</Typography>
                }
                {
                    this.state.loading
                        ?
                        (
                            <>
                                <Box height={30}></Box>
                                <Typography variant="h5" gutterBottom>We're just updating your vehicle table...</Typography>
                                <LinearProgress color="secondary"/>
                            </>
                        )
                        :
                        (
                            <></>
                        )
                }
                <Vehicles vehicles={this.state.vehicles} />
                
            </div>
        )
    }
}

export default HomePage;