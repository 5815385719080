/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react'
import { makeStyles, Theme, createStyles, useMediaQuery, useTheme, Grid, Typography, Box, Button } from '@material-ui/core'
import { useEffect } from 'react'
import { useRef } from 'react'

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    heroImage: {
        height: 'auto',
        gridArea: '1/1',
        display: 'grid'
    },
    heroImageDesktop: {
        height: 'auto',
        width: '100vw',
        display: 'grid',
        gridArea: '1/1'
    },
    heroText: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '3rem'
        },
        '@media (max-width: 375px)': {
            fontSize: '1rem',
            marginTop: '1.2rem'
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
        color: theme.palette.primary.contrastText
    },
    heroSub: {
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.8rem'
        },
        '@media (max-width: 375px)': {
            fontSize: '0.8rem'
        }
    },
    heroBox: {
        position: 'relative',
        placeItems: 'flex-start',
        display: 'grid',
        gridArea: '1/1',
        margin: '0rem 2rem 0rem 2rem',
        [theme.breakpoints.up('sm')]: {
            padding: '0rem 0rem 0rem 2rem'
        },
        [theme.breakpoints.up('md')]: {
            padding: '0rem 0rem 0rem 3rem'
        },
        backgroundColor: 'white'
    },
    heroButton: {
        borderRadius: '25px',
        fontFamily: 'Montserrat',
        fontSize: '1rem',
        marginTop: '1rem',
        textTransform: 'capitalize',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.3rem'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '2rem'
        },
        '@media (max-width: 375px)': {
            fontSize: '0.8rem'
        },
        '& div': {
            textDecoration: 'none'
        },
        color: '#263948',
    },
    hiddenLink: {
        textDecoration: 'none'
    }
    })
)

interface HeroProps {
    mainText: string
    bodyText: any
    ctaLink?: string
    ctaText?: string
    inverse?: boolean
}

function marginFunc(inverse: boolean | undefined, biggerThanMobile: boolean) {
    if (inverse && biggerThanMobile) {
        return { marginRight: '55%' }
    }
    if (biggerThanMobile) {
        return { marginLeft: '55%' }
    }
    return {}
}

export default function Hero(props: HeroProps) {
    const classes = useStyles()
    const theme = useTheme()
    const biggerThanMobile = useMediaQuery(theme.breakpoints.up('sm'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    const videoRef = useRef(null)
    useEffect(() => {
        if (videoRef != null && videoRef.current != null) {
            (videoRef.current as any).defaultMuted = true
        }
    })
    const justifyContent = biggerThanMobile ? 'flex-start' : 'center';
    return (
        <div>
            <div style={{ display: 'grid', minHeight: '100vh' }}>
                <div className={classes.heroImageDesktop} style={{ backgroundColor: biggerThanMobile ? '#263948' : theme.palette.background.paper, width: '50vw' }}>
                </div>
                <Box className={classes.heroBox} style={marginFunc(props.inverse, biggerThanMobile)}>
                    <div>
                        {biggerThanMobile ? <div style={{ height: '10vh' }} /> : <div style={{ height: '1vh' }} />}
                        {isDesktop && <div style={{ height: '5vh' }} />}
                        <Typography className={classes.heroText} variant="h6">
                            {props.mainText}
                        </Typography>
                        <Typography variant="body1" className={classes.heroSub}>
                            {props.bodyText}
                        </Typography>
                        <Grid container style={{ justifyContent: justifyContent }}>
                            {props.ctaLink && props.ctaText && (
                                <Button href={props.ctaLink} className={classes.heroButton} variant="outlined">
                                    {props.ctaText}
                                </Button>
                            )}
                        </Grid>
                    </div>
                </Box>
            </div>
        </div>
    )
}