import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const primary = "#E1FF00";
const secondary = "#5ADBFF";
// eslint-disable-next-line @typescript-eslint/no-use-before-define
const error = "#FE9000";
const contrast = "#263948";

const backgroundColor = "#263948"
const timberwolf = "#DBD7D2";
const resedaGreen = "#778071";
const secondaryText = timberwolf;
const primaryText = '#fff'
// A custom theme for this app
let newTheme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          scrollbarColor: `${primary} ${backgroundColor}`
        },
      },
    }
	},
  shape: {
    borderRadius: 10
  },
  typography: {
    h1: {
      fontFamily: "Manrope,EuclidCircular,Roboto,sans-serif",
    },
    h2: {
      fontFamily: "Manrope,EuclidCircular,Roboto,sans-serif",
    },
    h3: {
      fontFamily: "Manrope,EuclidCircular,Roboto,sans-serif",
    },
    h4: {
      fontFamily: "Manrope,EuclidCircular,Roboto,sans-serif",
    },
    h5: {
      fontFamily: "Manrope,EuclidCircular,Roboto,sans-serif",
    },
    h6: {
      fontFamily: "Manrope,EuclidCircular,Roboto,sans-serif",
    },
    subtitle1: {
      fontFamily: "Manrope,EuclidCircular,Roboto,sans-serif",
    },
    subtitle2: {
      fontFamily: "Manrope,EuclidCircular,Roboto,sans-serif",
    },
    fontFamily: "Manrope,EuclidCircular,Roboto,sans-serif",
  },
  palette: {
    mode: 'dark',
    primary: {
      main: secondary,
      contrastText: contrast,
    },
    secondary: {
      main: primary,
    },
    error: {
      main: error,
    },
    warning: {
      main: error,
    },
    info: {
      main: primary,
    },
    success: {
      main: secondary,
    },
    background: {
      default: backgroundColor,
      paper: backgroundColor
    },
    darkGreen: {
      main: resedaGreen
    },
    text: {
      primary: primaryText,
      secondary: secondaryText,
      disabled: secondaryText
    },
  },
} as any);

newTheme = responsiveFontSizes(newTheme);

export { newTheme };
