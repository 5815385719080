import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Vehicles = ({ vehicles }: any) => {
  const classes = useStyles();
  return (
    <><TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>VIN</TableCell>
            <TableCell align="right">Make</TableCell>
            <TableCell align="right">Model</TableCell>
            <TableCell align="right">Year</TableCell>
            <TableCell align="right">Odometer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicles.map((row: any) => (
            <TableRow key={row.Vin}>
              <TableCell component="th" scope="row">
                {row.Vin}
              </TableCell>
              <TableCell align="right">{row.Make}</TableCell>
              <TableCell align="right">{row.Model}</TableCell>
              <TableCell align="right">{row.Year}</TableCell>
              <TableCell align="right">
                {(row.State.Mileage as number)?.toLocaleString("en", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} {row.State.Mileage === undefined ? "Missing" : "miles"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer></>
  );
}

export { Vehicles }